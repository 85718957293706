.playjam-section1 {
    /* margin-bottom: -15px;   */
    padding-top: 5%;
    width: 100%;
    height: 100%;
    transition: padding-top 0.3s ease;
}

 .playjam-detail {
    padding-top: 5%;
 }
 
 
.playjam-section2 {
    background-color: white; 
    padding-top: 3vh;
}  

@media (max-width: 1520px) {
    .playjam-section1 {
        padding-top: 7%;
    }
}

@media (max-width: 767px) {
    .playjam-section1 {
        padding-top: 15%;
    }
}



 