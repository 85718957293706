.sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-height: 100vh;
  padding: 10% 0; /* Adjust padding for space at the top and bottom */
  overflow: hidden;
  box-sizing: border-box;
  position: relative; /* To position clouds */
}

.sub-cards-container {
  background-color: #fff;
  border-radius: 50px;
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.playjam-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: none !important;
  width: 100%;
  max-width: 200px;
  padding: 2rem;
  margin: 20px;
  transition: transform 0.3s ease;
  min-height: 300px !important;
} 
.card-description ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-description img {
  width: 10% !important;
  margin-right: 10px;
}
.sub-images {
  width: 80%;
  margin-bottom: 1rem;
}

.sub-plan-title {
  font-family: 'ef_hyungydororong';
  font-size: 40px;
  margin-bottom: 2vh;
  line-height: 1.5;
  white-space: nowrap;
  font-style: normal;
}

.card-description {
  text-align: left;
}

.card-price {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.sub_card-button {
  background-color: #41c29c;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.sub_card-button:hover {
  background-color: #2cc799;
}

.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
}

.cloud1 {
  position: absolute;
  top: 55%;
  left: 3%;
}
.cloud2 {
  position: absolute;
  top: 80%;
  right: 10%;
}
.cloud3 {
  position: absolute;
  top: 60%;
  right: 10%;
}
.cloud4 {
  position: absolute;
  top: 79%;
  left: 15%;
}
.cloud5 {
  position: absolute;
  top: 60%;
  left: 15%;
}
.cloud6 {
  position: absolute;
  top: 80%;
  right: 30%;
}

@media (max-width: 768px) {
  .row {
    display: block;
    text-align: center;  
  }
  
  .sub-cards-container {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent; 
    padding: 5%; /* Optional, adjust as needed */

  }

  .sub-plan-title {
    font-size: 27px;
    white-space: inherit;
  }

  .playjam-cards {
    max-width: 90%; /* Increase max-width for larger cards */
    width: 90%; /* Make cards wider */
    padding: 1.5rem; /* Increase internal padding for more space inside */
    margin: 20px auto; /* Increase space between cards and center them */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
    border-radius:  50px !important; /* Optional: Add rounded corners for aesthetics */
  }


  .cloud {
    width: 30%; /* Shrink clouds on smaller screens */
  }
  .cloud1, .cloud3, .cloud5 {
    display: none;
  }
  .cloud2 {
    position: absolute;
    top: 94% !important;
    right: 2%;
  }
  .cloud4 {
    position: absolute;
    top: 63% !important;
    left: 2%;
  }
  .cloud6 {
    position: absolute;
    top: 31% !important;
    right: 2%;
  }

  .playjam-cards:nth-of-type(1)::after {
    display: none !important;
  }





}
