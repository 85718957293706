 
.playjam-section2-title {
  font-family: 'LeferiBaseType';
  font-size: 20px;
}

.playjam-section2-text {
  font-family: 'LeferiBaseTypeNormal';
  font-size: 13px;
  font-style: normal;
  line-height: 1.67;
  color: #000;
}

 
.max-width-email {
  max-width: 80%;
}

.playjam-btn-style {
  border-radius: 3rem;
  background-color: #c18ed8 !important;
  color: white;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  font-family: 'LeferiBaseType';
  font-weight: bold;
  font-size: 14px;
  border: none;
  height: auto;
  width: 100%;
  max-width: 150px !important;
  margin-top: 10px;
}
.playjam-btn-style2 {
  border-radius: 38px;
  background-color: #47CFA7 !important;
  color: white;
  text-align: center;
  font-family: 'LeferiBaseType-Bold', 'Leferi BaseType';
  white-space: nowrap;
  cursor: pointer; 
  font-weight: bold;
  border: none;

  padding: 15px;
  font-size: 20px;
  width: 100%;
  max-width: 170px; 
  height: 100%;
  max-height: 60px; 
 
}

.playjam-btn-download{
  border-radius: 38px;
  background-color: #47CFA7 !important;
  color: white;
  text-align: center;
  font-family: 'LeferiBaseType-Bold', 'Leferi BaseType';
  white-space: nowrap;
  cursor: pointer; 
  font-weight: bold;
  border: none;

  padding: 15px;
  font-size: 20px;
  width: 100%;
  max-width: 230px; 
  height: 100%;
  max-height: 60px; 
}

.playjam-pdf-checkbox {
  margin-bottom: 5%;
  font-family: 'ef_hyungydororong';
  font-size: 20px; 
  margin-right: 10px;
  text-align: center;
}
.go_back_img{
  width: 100%;
  max-width: 80px;
}

 
@media (max-width: 767px) { 
  .wagzak-playjam-btn {
    font-size: 12px; /* Smaller font size */
    padding: 8px; /* Smaller padding */
    max-width: 120px; /* Smaller max width */
  } 
  .pdf-btn-detail-img {
    width: 40px; /* Smaller size on mobile devices */
    height: 40px;
  }
  .pdf-btn-detail-img-card {
    width: 25px; /* Smaller size on mobile devices */
    height: 40px; 
  }
  .playjam-pdf-checkbox{
    font-size: 15px;
  }
  .go_back_img{
    max-width: 70px;
  }
  .playjam-btn-style2 { 
    font-size: 15px;
    max-width: 100px;
    max-height: 40px;
    padding: 10px; 
  } 
  .playjam-btn-download {
    font-size: 14px;
    max-width: 150px;
    max-height: 40px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .wagzak-playjam-btn {
    font-size: 10px; /* Even smaller font size */
    padding: 6px; /* Even smaller padding */
    max-width: 100px; /* Even smaller max width */
  }
 
}
