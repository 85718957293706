.characters-section1 { 
  padding-top: 4%;
  width: 100%;
  height: 100%;
  transition: padding-top 0.3s ease; 
}

.characters-section2 {
  margin-top: 15%;
  margin-bottom: 15%;
  width: 100%;  
}

@media (max-width: 1820px) {
  .characters-section1 {
      padding-top: 10%;
  }
}