

/* margin top */
.m-top-10 {
    margin-top: 10%;
}
.m-top-20 {
    margin-top: 20%;
}
.m-top-5 {
    margin-top: 5%;
}

.m-top-3 {
    margin-top: 3%;
}



/* padding top */
.p-top-20 {
    padding-top: 20px;
} 
.p-top-10 {
    padding-top: 10%;
} 


.p-bottom-5-p {
    padding-bottom: 5%;
}


.p-bottom-10-p {
    padding-bottom: 10%;
}





/* margin bottom */
.m-bottom-10 {
    margin-bottom: 10%;
}

.m-bottom-5 {
    margin-bottom: 5%;
}







/* Flex container */
.flex-container-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}



/* Mobile screen */
/* @media (max-width: 767px) {
} */