
.home-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; 
    flex-direction: column;  
}

.section1 {  
    background-color: #72ABFF !important;  
    padding-top: 3%;
}

.section2, .section3, .section4 {
    background-color: rgb(255, 255, 255);
    margin-top: 10%;
}

.section5 {
    background-image: linear-gradient(to bottom, #ffffff, #74a4ed);
    height: auto;
    vertical-align: bottom;
    margin-bottom: 5%;
    margin-top: 5%;
}

.section5-1 {
    background-color: #ffffff;
    margin-top: -20px;
    padding-top: 10%; 
    width: 100%;

}

.section6 {
    background-color: rgb(255, 255, 255);
    width: 100%
}


.section7, .section8, .section9 {
    background-color: #a8e25d; 

}

.section10 {
    overflow: visible;
}


 

@media (max-width: 767px) {
    .section2 {
        display: none;


    }

    .section3 {
        padding-top: 3em;
    }
}
 