.home-background-style {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: visible !important;
}

.wagzak-family-mainpage {
  position: relative;
  display: flex;
  align-items: center;
  background-image: url('/public/images/home/wagzak-fam-background-cloud.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100%;
  height: 100vh;
  width: 100%;
  margin-bottom: -22px;
  z-index: 1000;
  margin-top: 1%;
  overflow: visible !important;
}

.second-cloud {
  position: absolute; /* Absolute position */
  bottom: -150px; /* Align to the bottom */
  height: 35%; /* Adjust height as needed */
  width: 100%;
  background-image: url('/public/images/home/wagzak-fam-cloud-front-cloud.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1001;
}

.container-wagzak-fam {
  position: absolute;
  padding-left: 5%;
  top: 145px;
  left: 40px;
  right: 40px;
  /* top: 185px;
  left: 40px;
  right: 40px;  */
  overflow: visible !important;
  pointer-events: none;
}

.container-wagzak-fam a,
.container-wagzak-fam button,
.container-wagzak-fam input,
.container-wagzak-fam img {
  pointer-events: auto; /* Re-enable pointer events for interactive elements */
}

.section1-title-style {
  font-family: 'ef_hyungydororong';
  font-size: 3rem;
  text-align: center;
  color: #ff0;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: 5px;
  white-space: nowrap;
  opacity: 0.1;
}

.mask {
  overflow: visible;
} 

.letters-mb {
  width: 40%;
  transform: translate(-50%, -50%);
}
.wagzak-letters-container {
  display: flex;
  flex-direction: row;  
  align-items: center;  
  width: 100%; 
  overflow: visible !important;
 
}
.wagzak-letters {
  display: none;
}

.arcoloring-mb {
  width: 50% !important;
  /* margin-top: 5%; */
}

/* Wagzak Family Spline animation style */
.pos {
  width: 100%; 
  align-items: center;
  height: 80vh;
  z-index: 0; /* Ensure it stays on top */
  pointer-events: auto;
  position: relative;
  overflow: visible;
  margin-right: 0%;

}

.spline-iframe {
  border: none;
  width: 100%;
  max-height: 100%;
  height: 92vh;
  
}

 
/* Media queries for responsive webpage */
/* Media queries for responsive webpage */
/* Media queries for responsive webpage */

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .home-background-style {
    height: auto; 
  }
  .second-cloud {
    display: none;

  }
  .container-wagzak-fam {
    top: 85px;
  }
  .wagzak-family-mainpage {
    max-height: 100%;
    height: 74vh;
    margin-top: 10%;
  }
  .spline-iframe {
    max-height: 100%;
    height: 88vh;
  }
  .pos {
    height: 55vh;
    margin-left: 0px;
  }
  .section1-title-style {
    padding-top: 15%;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  .letters-mb {
    display: none;
  }
  .wagzak-letters {
    display: block;
    width: 95%;
  }
  .arcoloring-mb {
    width: 55% !important;
  }
}


/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .section1-title-style {
    padding-top: 5%;
    margin-bottom: 0;
    font-size: 2rem !important;
  }

  .second-cloud {
    display: none;
  }
  .letters-mb {
    display: none;
  }

  .wagzak-family-mainpage {
    height: auto; 
  }

  .container-wagzak-fam {
    top: 150px;
  }
  .arcoloring-mb {
    margin-top: 10px;
    width: 45% !important;
  }
  .pos {
    margin-left: 0px;
  }
}

 
/* Desktops and Small Laptops(also some Tablets-ipadPro11인치-1025px) (1025px to 1439px) */
@media (min-width: 1025px) and (max-width: 1439px) {
  .section1-title-style {
    padding-top: 0;
    margin-bottom: 0;
    font-size: 2.5 rem !important;
  }
  .second-cloud {
    display: none;
  }
  .wagzak-letters {
    display: none;
  }
  .wagzak-family-mainpage {
    height: 90vh; 
  }

  .container-wagzak-fam {
    top: 145px;
    left: 0;
  }
  .arcoloring-mb {
    margin-top: 2%;
    width: 40% !important;
  } 
  .pos {  
    height: 85vh; 
    margin-right: 3%;
  }
}

/* Large Screens (1440px to 2559px) My macbook-13인치 + BENq monitor big one and BENQ vertical is (max-widht: 1899px) */
@media (min-width: 1440px) and (max-width: 2559px) {
   .wagzak-family-mainpage {
    height: 79vh; 
  }
 
  .container-wagzak-fam {
    top: 145px;
    left: 0; 
  }
 
  .section1-title-style {
    font-size: 3.5rem !important;
    padding-top: 0;
  }

  .arcoloring-mb {
    width: 40% !important;
  }

  .pos {
    height: 75vh; 
    /* margin-right: 2%; */
  }
  .second-cloud {
    display: none;
  }
  
}

/* Extra-Large Screens (2560px and above)  */
@media (min-width: 2560px) {
  .container-wagzak-fam {
    top: 165px;
    left: -15px;; 
  }
  .wagzak-family-mainpage {
    height: 90vh; 
  }
  .section1-title-style {
    font-size: 5rem;
  }
  .arcoloring-mb {
    width: 30% !important;
  }
  .pos {
    height: 95vh; 
  }
  .second-cloud {
    display: block;
    bottom: -130px;
  }
}




/* 
@media (max-width: 1024px) {
  .second-cloud {
    display: none;
  }
  .wagzak-family-mainpage {
    height: auto;
  }
  .wagzak-letters {
    display: none;
  }
}

@media (max-width: 1280px) {
  .second-cloud {
    display: none;
  }
  .wagzak-family-mainpage {
    height: auto;
    padding-top: 5%; 
  }
  .wagzak-letters {
    display: none;
  }

  .container-wagzak-fam {
    top: 185px;
  }

  .arcoloring-mb {
    margin-top: -10px;
    width: 45% !important;
  }
  .pos {
    margin-left: 0px;

  }
}
 
@media (max-width: 770px) {
  .home-background-style {
    height: auto;
  }
  .second-cloud {
    display: none;
  }
  .container-wagzak-fam {
    top: 120px;
  }

  .wagzak-family-mainpage {
    max-height: 100%;
    height: 98vh;
    margin-top: 10%;
  }
  .spline-iframe {
    max-height: 100%;
    height: 92vh;
  }
  .pos {
    height: 60vh;
    margin-left: 0px;
  }

  .section1-title-style {
    padding: 5%;
    margin-bottom: 10%;
    font-size: 1.4rem;
  }
  .letters-mb {
    display: none;
  }
  .wagzak-letters {
    display: block;
    width: 95%;
  }
  .arcoloring-mb {
    width: 55% !important;
  }
} */
