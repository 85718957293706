
.about-crew-section-style, .about-crew-container{
    width: 100%;
}
.aboutus-crew-title {
    font-family: 'ef_hyungydororong';
    font-size: 40px;
    font-stretch: normal;
    line-height: 2.60;
    letter-spacing: normal;
    white-space: nowrap;

}
.all-crewmembers-container {
    position: relative;
    height: 125rem;
}
.hoverable-card{
    position: relative;
}
.lottie-container{
    position: absolute;
    transition: .8s ease-in-out !important;
}
.lottie-container:hover{
    transform: scale(1.1);
    transition: .8s ease-in-out !important;
}




.leejae-unfixed-button {
    position: absolute;
    top: -5rem;
    left: 6rem;
}

.leejae-container{
    width: 380px;
    position: absolute;
    top: 11rem;
    left: 0;
    z-index: 1;
}


.sunpower-unfixed-button {
    position: absolute;
    top: -5rem;
    right: 6rem;
}

.sunpower-container {
    width: 380px;
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 1;
}



.grace-container{
    width: 450px;
    position: absolute;
    top: 38rem;
    left: -4rem;
    z-index: 1;
}
.grace-unfixed-button{
    position: absolute;
    top: 1rem;
    left: 49%;
}


.sush-container {
    width: 300px;
    position: absolute;
    z-index: 2;
    top: 48rem;
    left: 15rem;
}
.sush-unfixed-button {
    position: absolute;
    top: -4rem;
    right: 5rem;
}

.wina-unfixed-button {
    position: absolute;
    top: 6%;
    right: 4rem;
}
.wina-container {
    width: 400px;
    position: absolute;
    top: 35rem;
    right: -2rem;
}

.kumush-container {
    width: 215px;
    position: absolute;
    z-index: 2;
    top: 75.5rem;
    left: -2rem;
}
.kumush-unfixed-button {
    position: absolute;
    top: -4.5rem;
    left: 2rem;
}

.gulzat-container {
    width: 220px;
    position: absolute;
    z-index: 2;
    top: 75rem;
    left: 6rem;
}
.gulzat-unfixed-button {
    position: absolute;
    top: -5rem;
    left: 3rem;
}

.calvin-container {
    width: 200px;
    position: absolute;
    z-index: 2;
    top: 72rem;
    left: 15rem;
}
.calvin-unfixed-button {
    position: absolute;
    top: -6.5rem;
    right: 0;
}


.tigger-container {
    width: 420px;
    position: absolute;
    z-index: 1;
    top: 70rem;
    right: 0; 
    left: 60%;
    padding-left: 20px;
    
}
.tigger-unfixed-button {
    position: absolute;
    top: -4rem;
    right: 2rem;
}


.bob-container {
    width: 400px;
    position: absolute;
    z-index: 1;
    top: 90rem;
    right: 0;
}
.bob-unfixed-button {
    position: absolute;
    top: -1rem;
    right: 5rem;
}
.onemad-container {
    width: 400px;
    position: absolute;
    z-index: 1;
    top: 105rem;
    left: 0;
}
.onemad-unfixed-button {
    position: absolute;
    top: -7rem;
    left: 6.5em;
}

@media (max-width: 1200px) {
    .all-crewmembers-container {
        height: 125rem;
    }
    .leejae-container, .sunpower-container{
        width: 320px;
    }
    .grace-container{
        width: 360px;
        top: 33rem;
    }
    .grace-unfixed-button{
        top: 0.5rem;
        left: 10rem;
    }
    .sush-container {
        width: 250px;
        top: 40.5rem;
        left: 11.5rem;
    }
    .sush-unfixed-button {
        position: absolute;
        top: -4rem;
        right: 5rem;
    }
    .wina-container {
        width: 380px;
        top: 28rem;
        right: -5rem;
    }
    .kumush-container {
        width: 160px;
        top: 65.5rem;
        left: -1rem;
    }
    .kumush-unfixed-button {
        top: -4.5rem;
        left: 0.5rem;
    } 
    .gulzat-container {
        width: 190px;
        top: 64rem;
        left: 6rem;
    }
    .gulzat-unfixed-button {
        top: -5rem;
        left: 2rem;
    }
    .calvin-container {
        width: 170px;
        top: 62rem;
        left: 14rem;
    }
    .calvin-unfixed-button {
        top: -6rem;
    }
    .tigger-container {
        width: 280px;
        top: 65rem;
        right: -4rem;
    }
    
    .bob-container {
        width: 350px;
        top: 80rem;
    }
    .onemad-container {
        width: 350px;
        top: 95rem;
    }
}
@media (max-width: 992px) {
    .all-crewmembers-container {
        height: 100rem;
    }
    .leejae-container, .sunpower-container{
        width: 260px;
    }
    .sunpower-container{
        right: -2rem;
    }
    .leejae-container{
        left: -2rem
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: 2rem;
    }
    .grace-container{
        width: 320px;
        top: 28rem;
        left: -6rem;
    }
    .grace-unfixed-button{
        top: 0;
        left: 9rem;
    }
    .sush-container {
        width: 200px;
        top: 36rem;
        left: 8rem;
    }
    .sush-unfixed-button {
        top: -4.5rem;
        right: 4rem;
    }
    .wina-container {
        width: 320px;
        top: 24rem;
        right: -4rem;
    }
    .wina-unfixed-button{
        right: 3rem;
        top: -1rem;
    }
    .kumush-container {
        width: 140px;
        top: 57.2rem;
        left: -2rem;
    }
    .gulzat-container {
        width: 150px;
        top: 57rem;
        left: 4rem;
    }
    .gulzat-unfixed-button {
        top: -5rem;
        left: 1rem;
    }
    .calvin-container {
        width: 140px;
        top: 55rem;
        left: 10.5rem;
    }
    .tigger-container {
        width: 260px;
        top: 53rem;
        right: -6rem;
    }
    .tigger-unfixed-button{
        top: -4rem;
        right: 0;
    }
    
    .bob-container {
        width: 300px;
        top: 70rem;
        right: -5rem;
    }
    .bob-unfixed-button{
        top: -2rem;
    }
    .onemad-container {
        width: 300px;
        top: 82rem;
        left: -3rem;
    }
}
@media (max-width: 767px) {
    .aboutus-crew-title {
        font-family: 'LeferiBaseType';
        font-size: 20px;
        font-stretch: normal;
        line-height: 2.60;
        letter-spacing: normal;
        white-space: nowrap;
    }
    .all-crewmembers-container{
        height: 84rem;
    }
    .leejae-container{
        width: 210px;
        top: 8rem;
    }
    .sunpower-container{
        width: 210px;
        top: 4rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: 1rem;
    }

    .grace-container{
        width: 280px;
        top: 25rem;
        left: -7rem;
    }
    .grace-unfixed-button{
        top: -1rem;
        left: 7.5rem;
    }
    .sush-container {
        width: 170px;
        top: 32rem;
        left: 5rem;
    }
    .sush-unfixed-button {
        top: -4.5rem;
        right: 2rem;
    }
    .wina-container {
        width: 280px;
        top: 20rem;
        right: -6rem;
    }
    .wina-unfixed-button{
        right: 2rem;
        top: -1rem;
    }
    .kumush-container {
        width: 120px;
        top: 50rem;
        left: -3rem;
    }
    .kumush-unfixed-button{
        left: -0.5rem;
    }
    .gulzat-container {
        width: 140px;
        top: 49rem;
        left: 2rem;
    }
    .gulzat-unfixed-button {
        left: 0.5rem;
    }
    .calvin-container {
        width: 120px;
        top: 48rem;
        left: 8rem;
    }
    .tigger-container {
        width: 230px;
        top: 45rem;
        right: -7rem;
    }
    .tigger-unfixed-button{
        top: -4rem;
        right: 0;
    }
    
    .bob-container {
        width: 270px;
        top: 60rem;
        right: -6rem;
    }
    .bob-unfixed-button{
        top: -2rem;
        right: 2rem;
    }
    .onemad-container {
        width: 280px;
        top: 70rem;
        left: -4rem;
    }
    .onemad-unfixed-button{
        top: -6rem;
        left: 4rem;
    }

}
@media (max-width: 612px) {
    .all-crewmembers-container{
        height: 75rem;
    }
    .leejae-container{
        width: 200px;
        top: 7rem;
    }
    .sunpower-container{
        width: 200px;
        top: 3rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: 0.5rem;
    }

    .grace-container{
        width: 250px;
        top: 21rem;
        left: -5rem;
    }
    .grace-unfixed-button{
        top: -1rem;
        left: 6rem;
    }
    .sush-container {
        width: 150px;
        top: 27rem;
        left: 6rem;
    }
    .sush-unfixed-button {
        top: -4.5rem;
        right: 1rem;
    }
    .wina-container {
        width: 250px;
        top: 17rem;
        right: -4rem;
    }
    .wina-unfixed-button{
        right: 0;
        top: -1rem;
    }
    .kumush-container {
        width: 110px;
        top: 43.5rem;
        left: -3rem;
    }
    .kumush-unfixed-button{
        left: -0.5rem;
    }
    .gulzat-container {
        width: 120px;
        top: 43rem;
        left: 1.5rem;
    }
    .gulzat-unfixed-button {
        left: 0;
    }
    .calvin-container {
        width: 110px;
        top: 41.5rem;
        left: 7rem;
    }
    .calvin-unfixed-button{
        top: -6rem;
        left: 0;
    }
    .tigger-container {
        width: 200px;
        top: 40rem;
        right: -3rem;
    }
    .tigger-unfixed-button{
        top: -4rem;
        right: 0;
    }
    
    .bob-container {
        width: 230px;
        top: 53rem;
        right: -3rem;
    }
    .bob-unfixed-button{
        top: -2rem;
        right: 1rem;
    }
    .onemad-container {
        width: 230px;
        top: 63rem;
        left: -2rem;
    }
    .onemad-unfixed-button{
        top: -5.5rem;
        left: 3rem;
    }
}
@media (max-width: 512px) {
    .all-crewmembers-container{
        height: 65rem;
    }
    .leejae-container{
        width: 180px;
        left: -0.5rem;
    }
    .sunpower-container{
        width: 180px;
        top: 3rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: 0;
    }
    .grace-container{
        width: 210px;
        top: 20rem;
        left: -4rem;
    }
    .grace-unfixed-button{
        top: -1.5rem;
        left: 4.5rem;
    }
    .sush-container {
        width: 130px;
        top: 25rem;
        left: 5rem;
    }
    .sush-unfixed-button {
        top: -4rem;
        right: 0.5rem;
    }
    .wina-container {
        width: 220px;
        top: 17rem;
        right: -4rem;
    }
    .wina-unfixed-button{
        right: 0;
        top: -2rem;
    }
    .kumush-container {
        width: 90px;
        top: 38.5rem;
        left: -1rem;
    }
    .kumush-unfixed-button{
        left: -1rem;
        top: -4.5rem;
    }
    .gulzat-container {
        width: 100px;
        top: 38rem;
        left: 2.7rem;
    }
    .gulzat-unfixed-button {
        left: -0.5rem;
        top: -4.5rem;
    }
    .calvin-container {
        width: 90px;
        top: 37rem;
        left: 7rem;
    }
    .calvin-unfixed-button{
        top: -5.5rem;
        left: 0;
    }
    .tigger-container {
        width: 150px;
        top: 32rem;
        right: -3rem;
    }
    .tigger-unfixed-button{
        top: -3.5rem;
        right: -1rem;
    }
    
    .bob-container {
        width: 200px;
        top: 46rem;
        right: -3rem;
    }
    .bob-unfixed-button{
        top: -2rem;
        right: 0;
    }
    .onemad-container {
        width: 200px;
        top: 53rem;
        left: -1rem;
    }
    .onemad-unfixed-button{
        top: -5.5rem;
        left: 2rem;
    }
}
@media (max-width: 465px) {
    .all-crewmembers-container{
        height: 58rem;
    }
    .leejae-container{
        width: 155px;
        top: 6rem;
        left: -1.5rem;
    }
    .sunpower-container{
        width: 155px;
        right: -1.5rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: -1rem;
    }
    .grace-container{
        width: 200px;
        top: 17rem;
    }
    .grace-unfixed-button{
        top: -1.8rem;
        left: 4.2rem;
    }
    .sush-container {
        width: 110px;
        top: 22.5rem;
        left: 4.8rem;
    }
    .sush-unfixed-button {
        top: -4.2rem;
        right: -0.2rem;
    }
    .wina-container {
        width: 180px;
        top: 15.5rem;
        right: -2.5rem;
    }
    .wina-unfixed-button{
        right: -1rem;
        top: -2rem;
    }
    .kumush-container {
        width: 80px;
        top: 36.3rem;
        left: -1rem;
    }
    .kumush-unfixed-button{
        left: -1.8rem;
        top: -4.2rem;
    }
    .gulzat-container {
        width: 90px;
        top: 36rem;
        left: 2.2rem;
    }
    .gulzat-unfixed-button {
        left: -1.2rem;
        top: -4.4rem;
    }
    .calvin-container {
        width: 90px;
        top: 34rem;
        left: 6rem;
    }
    .calvin-unfixed-button{
        top: -5rem;
        left: 0;
    }
    .tigger-container {
        width: 130px;
        top: 29rem;
        right: -2.3rem;
    }
    .tigger-unfixed-button{
        top: -3.5rem;
        right: -1.5rem;
    }
    
    .bob-container {
        width: 170px;
        top: 43rem;
        right: -2rem;
    }
    .bob-unfixed-button{
        top: -3rem;
        right: 0;
    }
    .onemad-container {
        width: 170px;
        top: 50rem;
        left: -0.5rem;
    }
    .onemad-unfixed-button{
        top: -5rem;
        left: 1rem;
    }
}
@media (max-width: 410px) {
    .all-crewmembers-container{
        height: 55rem;
    }
    .leejae-container{
        width: 145px;
        top: 6rem;
        left: -1.5rem;
    }
    .sunpower-container{
        width: 145px;
        right: -1.5rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: -1rem;
    }
    .grace-container{
        width: 190px;
        top: 17rem;
    }
    .grace-unfixed-button{
        top: -1.8rem;
        left: 3.7rem;
    }
    .sush-container {
        width: 100px;
        top: 22.5rem;
        left: 4.4rem;
    }
    .sush-unfixed-button {
        top: -4.5rem;
        right: -0.2rem;
    }
    .wina-container {
        width: 170px;
        top: 15rem;
        right: -2.5rem;
    }
    .wina-unfixed-button{
        right: -1rem;
        top: -2.5rem;
    }
    .kumush-container {
        width: 70px;
        top: 34rem;
        left: -1.5rem;
    }
    .kumush-unfixed-button{
        left: -1.8rem;
        top: -4.2rem;
    }
    .gulzat-container {
        width: 80px;
        top: 33.5rem;
        left: 1.5rem;
    }
    .gulzat-unfixed-button {
        left: -1.2rem;
        top: -4.4rem;
    }
    .calvin-container {
        width: 80px;
        top: 32rem;
        left: 4.6rem;
    }
    .calvin-unfixed-button{
        top: -5rem;
        left: 0;
    }
    .tigger-container {
        width: 150px;
        top: 31rem;
        right: -3rem;
    }
    .tigger-unfixed-button{
        top: -3.5rem;
        right: -1.5rem;
    }
    
    .bob-container {
        width: 160px;
        top: 40rem;
        right: -3rem;
    }
    .bob-unfixed-button{
        top: -3rem;
        right: 0;
    }
    .onemad-container {
        width: 160px;
        top: 47rem;
        left: -1rem;
    }
    .onemad-unfixed-button{
        top: -5rem;
        left: 1rem;
    }
}
@media (max-width: 355px) {
    .all-crewmembers-container{
        height: 47rem;
    }
    .leejae-container{
        width: 130px;
        top: 5rem;
    }
    .sunpower-container{
        width: 130px;
        top: 2rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: -1rem;
        top: -4.5rem;
    }
    .grace-container{
        width: 160px;
        top: 15rem;
        left: -3rem;
    }
    .grace-unfixed-button{
        top: -2rem;
        left: 2.5rem;
    }
    .sush-container {
        width: 90px;
        top: 19.3rem;
        left: 3.8rem;
    }
    .sush-unfixed-button {
        right: -0.9rem;
    }
    .wina-container {
        width: 150px;
        top: 12rem;
        right: -2.8rem;
    }
    .wina-unfixed-button{
        right: -1rem;
        top: -2.8rem;
    }
    .kumush-container {
        width: 60px;
        top: 30rem;
        left: -1.3rem;
    }
    .kumush-unfixed-button{
        left: -2rem;
        top: -4.5rem;
    }
    .gulzat-container {
        width: 70px;
        top: 29.5rem;
        left: 1.2rem;
    }
    .gulzat-unfixed-button {
        left: -1.5rem;
        top: -4.5rem;
    }
    .calvin-container {
        width: 70px;
        top: 28rem;
        left: 4.2rem;
    }
    .calvin-unfixed-button{
        top: -5rem;
        left: -1rem;
    }
    .tigger-container {
        width: 100px;
        top: 24rem;
        right: -2.3rem;
    }
    .tigger-unfixed-button{
        top: -4rem;
        right: -1.8rem;
    }
    
    .bob-container {
        width: 140px;
        top: 35rem;
        right: -2.5rem;
    }
    .bob-unfixed-button{
        top: -3rem;
        right: 0;
    }
    .onemad-container {
        width: 140px;
        top: 40rem;
        left: -1rem;
    }
    .onemad-unfixed-button{
        top: -5rem;
        left: 1rem;
    }
}
@media (max-width: 300px) {
    .all-crewmembers-container{
        height: 41rem;
    }
    .leejae-container{
        width: 100px;
        top: 4rem;
    }
    .sunpower-container{
        width: 110px;
        top: 2rem;
    }
    .leejae-unfixed-button, .sunpower-unfixed-button {
        left: -1rem;
        top: -4.9rem;
    }
    .grace-container{
        width: 140px;
        top: 13rem;
        left: -3rem;
    }
    .grace-unfixed-button{
        top: -2.8rem;
        left: 2rem;
    }
    .sush-container {
        width: 70px;
        top: 17rem;
        left: 3.2rem;
    }
    .sush-unfixed-button {
        right: -1.8rem;
    }
    .wina-container {
        width: 130px;
        top: 10.5rem;
        right: -2rem;
    }
    .wina-unfixed-button{
        right: -2rem;
        top: -2.8rem;
    }
    .kumush-container {
        width: 50px;
        top: 25.5rem;
        left: -1rem;
    }
    .kumush-unfixed-button{
        left: -2rem;
        top: -4.5rem;
    }
    .gulzat-container {
        width: 60px;
        top: 25rem;
        left: 1rem;
    }
    .gulzat-unfixed-button {
        left: -2rem;
        top: -4.5rem;
    }
    .calvin-container {
        width: 60px;
        top: 23.8rem;
        left: 3.5rem;
    }
    .calvin-unfixed-button{
        top: -5rem;
        left: -1rem;
    }
    .tigger-container {
        width: 80px;
        top: 21rem;
        right: -1.8rem;
    }
    .tigger-unfixed-button{
        top: -4.5rem;
        right: -2.2rem;
    }
    
    .bob-container {
        width: 120px;
        top: 30rem;
        right: -2.5rem;
    }
    .bob-unfixed-button{
        top: -3rem;
        right: 0;
    }
    .onemad-container {
        width: 120px;
        top: 35rem;
        left: -1rem;
    }
    .onemad-unfixed-button{
        top: -5rem;
        left: 1rem;
    }
}