 
.policy-style {
    width: 100%;
    max-width: 950px;
    margin: 10% auto;   
    padding-top: 10%; 
}

.policy-style > h3 {
    font-size: 32px;
    font-weight: bold;
    font-family: 'LeferiBaseType';
    color: #1f1e1e;
    /* text-align: center; */
}


.policy-style > p {
    font-size: 18px;  
    font-family: 'LeferiBaseTypeNormal';  
    line-height: 38px;
}

/* en version */

.pr_box {
    margin-bottom: 5%;
}

.pr_box > p {
    font-size: 18px;    
    line-height: 38px;
    margin-bottom: 5px !important;
    
}

.pr-tit {
    color: #1f1e1e;
    font-weight: bold;

}



.policy-style > p > strong {
    color: #1f1e1e;
}

@media only screen and (max-width: 768px) {
    .policy-style {
        font-size: 14px;
        padding: 15px;
        padding-top: 25%; 
    }
    /* h3 {
        font-size: 24px; 
    }
    */
    
}
