
 
.character-p-style {
    font-family: 'LeferiBaseTypeNormal';
    font-size: 18px;  
}


@media (max-width: 767px) { 
    .character-p-style{
        font-size: 14px;
    } 

}