/* Title */
.content-main-title-ef {
  font-family: 'ef_hyungydororong';
  font-size: 60px;
  margin-bottom: 2vh;
  line-height: 1.5;
  white-space: nowrap;
  font-style: normal;
}

.content-p-style {
  font-family: 'LeferiBaseTypeNormal';
  font-size: 23px;
  white-space: wrap;
  font-style: normal;
  line-height: 2.5;
  margin-bottom: 2vh;
}

.content-p-style-lef-base {
  font-family: 'LeferiBaseType';
  font-size: 23px;
  white-space: nowrap;
  font-style: normal;
  line-height: 2.5;
  margin-bottom: 2vh;
}

.email-title {
  font-family: 'LeferiBaseType';
  font-size: 50px;
  line-height: 1.38;
  margin-bottom: 2vh;
  color: #fba056;
  white-space: nowrap;
}

/* Mobile screen */
@media (max-width: 767px) {
  .content-main-title-ef {
    font-size: 27px;
    white-space: inherit;
  }
  .content-p-style {
    font-size: 13px;
    white-space: wrap !important;

  }
  .content-p-style-lef-base {
    font-size: 13px;
    white-space: wrap !important;

  }
  .email-title {
    font-size: 20px;
    white-space: inherit;
  }
}
