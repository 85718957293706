  
.mymodal-content {
  width: 90vh;
  height: 90vh;
  max-width: 90vw;
  max-height: 90vw;
  box-shadow: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
}

.modal-card {
  width: 80%;
  height: 80%;
}
.modal-card > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-description {
  height: 45%;
  justify-content: flex-start;
  text-align: center;
  padding: 10vmin;
  margin-bottom: 10px;
  
}
/* General styles for all description lines */
.modal-description p {
  font-size: 1.8vmin;
  font-family: 'LeferiBaseTypeNormal';
  margin: 0.5px;
  padding: 0.4vmin; 
}

/* Specific styles for name and position */
.modal-description .name {
  font-size: 5vmin;
  font-weight: bold;
  font-family: 'ef_hyungydororong';
}

.modal-description .position {
  font-size: 3vmin;
  font-weight: 200;
  padding-bottom: 1vmin;
}

 

.modal-lottieholder {
  height: 50%;
}
.close-button { 
  width: 6vmin;
  height: 6vmin;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  cursor: pointer;
  transform: scale(1.2);
}


/* subsscription */
 