 
.home-wagzakworld-style {
  background-image: url('/public/images/home/main_Wagzak App_01.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 5%;
}

.mb-style {
  max-width: 70% !important;
}

.wagzakworld-btn {
  /* border-radius: 3rem; */
  background-color: #ff6e6b !important;
  color: white;
  white-space: nowrap;
  border-radius: 38px;
  cursor: pointer;
  text-align: center;
  font-family: 'LeferiBaseType';
  font-weight: bold;
  border: none;
  margin-top: 8px;

  font-size: 20px;
  padding: 15px;
  width: 100%;
  max-width: 170px;
  height: 100%;
  max-height: 60px;
  /* height: auto ;
    font-size: 14px;
    width: 100% ; 
    max-width: 150px !important; */
}

.world-text-1 {
  font-size: 15px;
  margin-top: 30%;
  font-family: 'LeferiBaseType';
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  color: #ff6e6b;
  white-space: nowrap;
}

.world-text-2 {
  font-size: 40px;
  font-family: 'ef_hyungydororong';
  font-stretch: normal; 
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  color: #414141;
}

.world-text-3 {
  font-family: 'LeferiBaseTypeNormal';
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  color: #414141;
  white-space: nowrap;
  margin: 5% 0;
}

@media (max-width: 1196px) {
  .world-text-3,
  .world-text-1 {
    font-size: 12px;
  }

  .world-text-1 {
    margin-top: 30%;
  }

  .world-text-2 {
    font-size: 20px !important;
    margin-top: 0%;
  } 
}

@media (max-width: 988px) {
  .world-text-3,
  .world-text-1 {
    font-size: 9px;
  }

  .world-text-1 {
    margin-top: 30% ; 
    margin-bottom: 0rem;
  }

  .world-text-2 {
    font-size: 17px;
    margin-top: 5px;
  }
  .wagzakworld-btn {
    font-size: 15px;
    max-width: 100px;
    max-height: 40px;
    padding: 10px;
    margin-top: 2px;
  }
}

@media (max-width: 767px) {
  .mb-style {
    max-width: 50%;
    margin-top: 47% !important;
  }

  .wagzakworld-btn {
    font-size: 13px;
    max-width: 100px;
    max-height: 40px;
    padding: 10px;
  }

  .home-wagzakworld-style {
    padding-top: 0%;
    background-size: 100%;
  }

  .world-text-3,
  .world-text-1 {
    font-size: 10px;
  }

  .world-text-1 {
    margin-top: 40%;
    margin-bottom: 0rem;
  }

  .world-text-2 {
    font-size: 15px;
    margin: 3% 0;
  }

 
}
