.button-panel { 
    position: fixed;
    bottom: 10px; /* Adjust as needed */
    right: 70px; /* Adjust as needed */
    z-index: 1000;

}

.menu-mb {
    width: 150%;
}

.app-downlaod-btns {
    width: 110%;
    padding: 5%;

} 

.dropup .dropdown-toggle::after {
    display: none !important;
  }

/* Custom CSS to remove the border */
.btn.border-none:focus {
    outline: none;
    /* Remove the outline border */
    border: none;
    /* Remove any other border styles */
}

a {
    color: white;
    outline: none;
    /* Add this line to remove the blue outline */
}

@media (max-width: 767px) {
    .button-panel {
        bottom: 40px; /* Adjust as needed */
        right: 5px; /* Adjust as needed */ 
    }
    .menu-mb {
        width: 100%;
    }
    .app-downlaod-btns {
        width: 90%; 
    
    }
    
    
}