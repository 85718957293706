 

 .footerIconsAboutus-style {
     background-image: url('/public/images/footer/aboutus_footer_blue.svg');
    background-position: top;
     justify-content: center;
     align-items: center;
     display: flex;
     background-repeat: no-repeat;
     background-size: cover;
     margin-bottom: -15px; 

 }


 .footerAboutUs-title-style {
     font-family: 'ef_hyungydororong';
     font-size: 90px;
     align-items: center;
     margin-bottom: 6%;
     color: #edf07e;
     white-space: nowrap;

 }

 .footerAboutUs-text-style {
     font-family: 'LeferiBaseTypeNormal';
     font-size: 40px;
     font-stretch: normal;
     letter-spacing: normal;
     color: white;
     line-height: 1.93;
     white-space: nowrap;
 }

 .max-width-btn {
     max-width: 50%;
 }

 .max-width-house {
     max-width: 80%;
 }

 .footerabout-us {
     padding: 10%;
 }

 @media (max-width: 990px) {

     .footerAboutUs-title-style {
         font-size: 55px;

     }

     .footerAboutUs-text-style {
         font-size: 25px;
     }


 }

 @media (max-width: 767px) {

     .footerAboutUs-title-style {
         font-size: 35px;
         margin-bottom: 5%;
         margin-top: 35%;

     }

     .footerAboutUs-text-style {
         font-size: 15px;
     }

     .max-width-house {
         max-width: 50%;
     }

     .footerabout-us {
         padding: 0%;
     }



 }