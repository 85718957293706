 
.home-playjam-style {
  background-image: url('/public/images/home/Wagzak_Playjam_new.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 5%;
}

.mb-style {
  max-width: 70% !important;
}

.wagzak-playjam-btn { 
  background-color: #c18ed8 !important;
  color: white;
  white-space: nowrap;
  border-radius: 38px;
  cursor: pointer; 
  text-align: center;
  font-family: 'LeferiBaseType';
  font-weight: bold;
  border: none;
  margin-top: 8px; 
  font-size: 20px;
  padding: 15px;
  width: 100%;
  max-width: 170px;
  height: 100%;
  max-height: 60px; 
}

.playjam-text-1 {
  margin-top: 30%;
  font-size: 15px;
  font-family: 'LeferiBaseType';
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  color: #c18ed8;
  white-space: nowrap;
}

.playjam-text-2 {
  font-size: 40px;
  font-family: 'ef_hyungydororong';
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  color: #414141;
}

.playjam-text-3 {
  font-family: 'LeferiBaseTypeNormal';
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  color: #414141;
  white-space: nowrap;
  margin: 5% 0;
}

@media (max-width: 1196px) {
  .playjam-text-3,
  .playjam-text-1 {
    font-size: 12px;
  }

  .playjam-text-1 {
    margin-top: 30% !important;
    /* margin-bottom: 0rem; */

  }

  .playjam-text-2 {
    font-size: 20px !important;
    margin-top: 0%;
  }
}

@media (max-width: 988px) {
  .playjam-text-3,
  .playjam-text-1 {
    font-size: 9px;
  }

  .playjam-text-1 {
    margin-top: 30% !important;
    margin-bottom: 0rem;
  }

  .playjam-text-2 {
    font-size: 17px;
    margin-top: 5px;
  }
  .wagzak-playjam-btn {
    font-size: 15px;
    max-width: 100px;
    max-height: 40px;
    padding: 10px;
    margin-top: 2px;
  }
}

@media (max-width: 767px) {
  .mb-style {
    max-width: 50%;
    margin-top: 47% !important;
  }
  .wagzak-playjam-btn {
    font-size: 13px;
    max-width: 100px;
    max-height: 40px;
    padding: 10px;
    /* max-width: 90px !important;
    font-size: 10px; */
  }

  .home-playjam-style {
    background-image: url('/public/images/home/Mob_Wagzak_Playjam_Eng.svg');
    padding-top: 0%;
    background-position: top;
    background-size: 100%;
  }

  .playjam-text-3,
  .playjam-text-1 {
    font-size: 10px;
  }

  .playjam-text-1 {
    margin-top: 40% !important;
    margin-bottom: 0rem;
  }

  .playjam-text-2 {
    font-size: 15px;
    margin: 5% 0;
  }
}
