 
.mb-layout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-text-layout p {
  color: #aba7a7; 

}
.footercontacts-style {
  background-color: rgb(255, 255, 255); 
}

.footer-contacts-p {
  color: #7fc1ff;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: nowrap;
}

.footercontacts-text-style {
  color: #ff89e1;
  font-family: 'ef_hyungydororong';
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  white-space: nowrap;
}

.footercontacts-style p {
  font-family: 'LeferiBaseType';
  font-size: 15px;
}
 



@media (max-width: 767px) {


  .mb-layout {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
 
  .footer-contacts-p {
    font-size: 25px;
  }

  .footercontacts-text-style {
    font-size: 15px;
  }

  .logo-footer  {
    margin-bottom: 5px;
  }

  .snslayout {
    margin-bottom: 15px; 
  }

  .g-music {
    margin-bottom: 5px;

  }

}