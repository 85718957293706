/* Font Faces */
@font-face {
  font-family: 'ef_hyungydororong';
  src: url('/src/assets/fonts/EF_hyunydororong.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LeferiBaseType';
  src: url('/src/assets/fonts/LeferiBaseType.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LeferiBaseTypeNormal';
  src: url('/src/assets/fonts/LeferiBaseRegular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}


 
