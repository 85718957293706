.characters-container {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  padding: 5%;
  /* margin: 5% 25% 25% 25% !important;  */
  overflow: hidden;   
}

.character-card {
  display: flex;
  flex-direction: column;
  align-items: center !important; 
  text-align: center !important;
  justify-content: center !important;
  position: relative;
  border: none; 
  border-radius: 20px !important;
  height: 100%;
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  overflow: hidden; 
    
}
 
.spline-color {
  width: 1210px !important;
  height: 1210px !important;
  overflow: hidden;   
}

.introduce-text {
  text-align: center !important;
  font-family: 'ef_hyungydororong';
  font-size: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%; 
}

.character-text-style {
  font-family: 'ef_hyungydororong';
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
}

.hand-icon {
  position: absolute;
  top: 70%;
  left: 50%;
  width: 60px !important;
  transform: translate(-50%, -50%);
  animation: moveAndBlink 3s infinite ease-in-out !important;
  cursor: pointer;
  z-index: 2;
}

@keyframes moveAndBlink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    left: calc(80% - 50px);
    opacity: 0;
  }
}
 

@media (min-width: 481px) and (max-width: 1199px) {
  .character-card {
    max-height: 250px;
    max-width: 350px;  
  }

  .spline-color {
    width: 1198px !important;
  }

  .introduce-text {
    text-align: center !important; 
    font-size: 30px;
    justify-content: center !important;
    align-items: center !important;  
  }

  .hand-icon {
    top: 70%;
    left: 50%;
    width: 40px !important;
  }
}

@media (max-width: 480px) {
  .characters-container {
    padding: 20%;
  }
  .character-card {
    max-height: 160px;
    max-width: 350px;
  }
  .spline-color {
    width: 479px !important;
  }
  .introduce-text {
    font-size: 24px;
  }
  .hand-icon {
    top: 80%;
    width: 30px !important;
  }
}
