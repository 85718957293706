 

.content-req-title {
  font-family: 'ef_hyungydororong';
  font-stretch: normal;
  line-height: 1.38;
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-style: normal;
  letter-spacing: normal; 
  white-space: nowrap;
}

.content-req-p {
  font-family: 'LeferiBaseTypeNormal';
  font-size: 15px;
  font-stretch: normal;
  line-height: 2.6;
  letter-spacing: normal;
  white-space: nowrap;
}

.typography-style {
  font-family: 'LeferiBaseType';
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
}

.input-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-style {
  width: 100%;
  max-width: 500px !important;
  height: 50px;
  border-radius: 50px 50px;
  background-color: #ffffff;
  border: 0.5px #dddddd solid;
  box-shadow: 0px 0px 10px rgba(249, 243, 243, 0.5);
  font-family: 'LeferiBaseType';
  font-size: 14px;
  color: #827c7c !important;
}

.input-style2 {
  width: 100%;
  max-width: 243px !important;
}

.costum-input-style {
  width: 100%;
  max-width: 168px !important;
  border-radius: 0 50px 50px 0 !important;
}
.costum-input-style2 {
  max-width: 500px;
  border-radius: 0 50px 50px 0 !important;
  font-size: 14px !important;
}

/* .costum-input-style::placeholder, 
.costum-input-style2::placeholder,
.input-style::placeholder,
.input-group::placeholder {
  font-weight: normal !important; 
} */

.prepend-style {
  height: 50px;
  background-color: #979797 !important;
  border: none;
  border-radius: 50px 0 0 50px !important;
  color: #ffffff !important;
  font-family: 'LeferiBaseType';
  font-size: 13px !important;
  outline: none;
}

.btn-style {
  background-color: #47cfa7;
  color: #ffffff;
  font-family: 'LeferiBaseType-Bold', 'Leferi BaseType';
  font-weight: bold;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  border-radius: 38px;
  
  font-size: 18px;
  padding: 15px;
  width: 100%;
  max-width: 180px; 
  height: 100%;
  max-height: 60px;
}
.btn-style:disabled {
  opacity: 0.35 !important;
}

input.input-error {
  border: 1px solid #ff4945;
}
.error-message {
  color: red;
  font-size: 1vmin;
  width: 100%;
  max-width: 470px;
  margin: 5px auto;
  white-space: nowrap;
}
.error-message2 {
  color: red;
  font-size: 1vmin;
  width: 100%;
  max-width: 230px !important;
  margin: 5px 0;
  white-space: nowrap;
}
.result-style {
  text-align: center;
  font-size: 2vmin;
}
.p-style {
  text-align: center;
  font-size: 2vmin;
  color: #47cfa7;
}
@media (max-width: 767px) {
  .input-container {
    width: 70%;
    margin: 0 auto;
  }

  .input-style,
  .input-style2,
  .costum-input-style,
  .costum-input-style2 {
    width: 70%;
    height: 40px;
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .prepend-style {
    height: 40px;
    font-size: 10px !important;
  }
  .input-style2 {
    max-width: 500px !important;
  }
  .costum-input-style {
    max-width: 298px !important;
  }

  .error-message2,
  .error-message {
    max-width: 470px;
    width: 65%;
  }
  .content-req-p,
  .typography-style {
    font-size: 10px !important;
  }
  .content-req-title {
    font-size: 20px;
  }

  .btn-style {
    font-size: 14px;
    padding: 12px; 
    max-width: 140px; 
    max-height: 45px;
  }

  .step-image-style {
    width: 70%;
    height: 60%;
  }
}

@media (min-width: 992px) {
  .input-style,
  .input-style2,
  .error-message,
  .error-message2,
  .costum-input-style,
  .costum-input-style2 {
    width: 50%;
  }
}
