 
.content-section3-title {
    font-family: 'LeferiBaseType';
    font-size: 20px;
    padding-top: 5px;

}

.content-section3-text {
    font-family: 'LeferiBaseTypeNormal';
    font-size: 14px;
    font-style: normal;
    line-height: 1.67;
    color: #000;
}

.card {
    transition: transform 0.3s ease;
}


.card:hover {
    transform: scale(1.05);
}