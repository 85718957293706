 
/* auth container style */
.custom-container {
  width: 100%;
  margin: 0 0;
  padding: 0 0;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

 
/* Form Styling */
.form-style {
  width: 100%;
  max-width: 500px;
  margin-bottom: 0;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 0 30px;
}

.input-style {
  color: #939191 !important;
  border: none !important;
  border-bottom: 1px solid #d8d8d8 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0% !important;
}

.input-style:focus {
  outline: none !important;
  border-color: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #c3c0c0 !important;
}

/* Placeholder Styling */
.input-style::placeholder {
  color: #c4bfbf !important;
}

/* Title Styling */
.login-title,
.signup-title {
  white-space: nowrap;
  color: #41c39d;
  font-weight: bold;
  font-family: 'ef_hyungydororong';
  font-size: 25px;
  padding-bottom: 15px;
}

.login-title {
  color: #a67add !important;
}

/* Button Styling */
.signup-btn,
.login-btn {
  border-radius: 38px !important;
  background-color: #47cfa7 !important;
  color: white;
  text-align: center;
  font-family: 'LeferiBaseType' !important;
  white-space: nowrap !important;
  cursor: pointer;
  font-weight: bold !important;
  padding: 15px !important;
  font-size: 20px !important;
  width: 100% !important;
  max-width: 230px !important;
  height: 100% !important;
  max-height: 60px !important;
}

.signup-btn {
  background-color: #ff6e6b !important;
}

/* error message style */
.form-style .error-message {
  color: red !important;
  font-size: 0.71em !important;
  margin-top: 0.15rem !important;
  width: 100% !important;
  text-align: left !important;
  display: block;
}

.signin-p,
.signup-p {
  font-size: 20px;
  font-family: 'LeferiBaseType';
  display: inline-block; /* Ensure the decoration is applied just to the text width */
  text-decoration: underline; /* Apply underline directly to the text */
  text-decoration-color: #a67add; /* Change the color as needed */
  color: #a67add;
  cursor: pointer; /* Make the cursor a pointer to indicate it's clickable */
  text-decoration-thickness: 2px; /* Adjust the thickness as needed */
}
.signup-p {
  text-decoration-color: #41c39d;
  color: #41c39d;
}

/* footer img */
.bottom-img {
  width: 100%;
  height: 100%;
}

/* User Auth style & Subscription */
/* User Data */
.user-account {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 0;
  padding: 0 0;
  position: relative;
}
 


.user-data > h1 {
  color: #41c39d;
  text-wrap: nowrap;
}

.user-label {
  color: #c4bfbf;
  font-weight: bold;
  font-size: 1em;
}
.user-container,
.subscription-container {
  flex: 1;
  margin: 10px;
}

/* User Subscription */
.subscription-title {
  margin-bottom: 10%;
  color: #a67add;
}


.subscribtion-container {
  margin-top: 10%;
  background-color: #D9D9D9;
  width: 100%;
  max-width: 600px;
  height: 300px; 
  border-radius: 20px;
}

@media (max-width: 767px) {
  .signup-title,
  .login-title {
    font-size: 20px;
  }
  .form-style {
    margin-top: 10%;
  }
  .signin-p {
    font-size: 15px;
  }

  .signup-btn,
  .login-btn {
    font-size: 14px !important ;
    max-width: 150px !important;
    max-height: 40px !important;
    padding: 10px !important;
  }


}
