.navbar {
  position: fixed !important;
  overflow: visible !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  min-height: 100px;
  background: transparent;
  transition: background-color 0.3s ease;
}

/* Styles for larger screens */
@media (min-width: 992px) {
  .navbar.navbar-scrolled {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ); /* Glass-like gradient */
    backdrop-filter: blur(10px); /* Apply blur effect */
    -webkit-backdrop-filter: blur(10px);
  }
}

.nav-link, .select_box_title {
  font-family: 'LeferiBaseType';
  transition: color 0.3s ease !important;
}

.nav-link {
  color: white !important;
  font-size: 18px;
  white-space: nowrap;
}

.nav-link:hover, .header-text-style:hover {
  color: #ffcc00 !important;
}

.nav-link.active {
  color: #ffcc00 !important;
}

.navbar.navbar-scrolled .nav-link,
.navbar.navbar-scrolled .select_box_title {
  color: #72abff !important; /* Change font color on scroll */
}

.navbar.navbar-scrolled .select_box_title {
  border: 1.5px solid #72abff;
  background-image: url('/public/images/header/ico_arrow_downblue.svg');  
}

.navbar.navbar-scrolled .select_box_list,
.navbar.always-blue .select_box_list {
  border: 1.5px solid #72abff;
}

.navbar.navbar-scrolled .nav-link.active,
.navbar.always-blue .nav-link.active {
  color: #ffcc00 !important; /* Ensure active link stays yellow */
}

.navbar.always-blue .select_box_title {
  color: #72abff !important;
  border: 1.5px solid #72abff;
  background-image: url('/public/images/header/ico_arrow_downblue.svg');   
}

.navbar.always-blue .nav-link {
  color: #72abff !important; 
}

.logo_btn_mb {
  width: 65px;
}

.language-btn {
  margin-top: 20px;
}

.user-btn {
  margin-top: 9px;
  margin-left: 13px;
}

.header-text-style {
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.3rem) !important;
  white-space: nowrap;
}

.int_select {
  position: relative;
  z-index: 1010 !important; /* Ensure it's above other elements */
}

.select_box_title {
  padding: 13px 40px 13px 30px;
  background-repeat: no-repeat;
  background-position: 94% center;
  background-position: calc(100% - 20px) center; /* Adjust the arrow position */
  background-image: url('/public/images/header/ico_arrow_down.svg');
  border: 1.5px solid white;
  border-radius: 30px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: white;
}

.select_box_title.active {
  border-color: white;
  animation: shine 2s infinite;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

@keyframes shine {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
}

.select_box_list {
  display: none;
  position: absolute;
  top: 61px;
  left: 0;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 25px;
  box-sizing: border-box;
  overflow: visible !important;
  font-family: 'LeferiBaseType';
  z-index: 1020;
}

.select_box_list.show {
  display: block;
}

.select_box_list .dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.select_box_list ul {
  position: relative;
  z-index: 4;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.select_box_list a {
  display: block;
  align-items: center;
  width: 100%;
  height: 43px;
  padding: 10px 20px;
  border-radius: 30px; /* Remove border-radius */
  font-size: 16px;
  color: #060606;
  box-sizing: border-box;
  text-decoration: none; /* Remove underline */
}

.select_box_list a span {
  font-weight: 600;
  font-size: 15px;
}

.select_box_list a.active,
.select_box_list a:active,
.select_box_list a:hover {
  background-color: #72abff;
  color: white !important;
}

@media (max-width: 991px) {
  .sidebar {
    background-color: white;
    overflow: visible;
  }

  .language-btn {
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .user-btn {
    margin-top: 10px;
    margin-left: 0px;
  }

  .header-text-style {
    color: #3b3b37 !important;
  }

  .logo_btn_mb {
    width: 45px;
    padding-left: 5px;
  }

  .offcanvas.show .select_box_title {
    background-color: #72abff !important;
    color: white !important;
    font-weight: bold;
    margin-bottom: 10px; /* Add some space between the title box and the list */
  }

  .select_box_list {
    top: auto;
    bottom: 100%;
    margin-bottom: 10px; /* Add some space between the title box and the list */
    animation: shine 1s forwards;
    box-shadow: 0 0 10px #72abff;
  }

  .nav-link.active {
    color: #72abff !important;
  }
}
