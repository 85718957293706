.btn-holder{
    width: 120px;
    height: 120px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.back-beam {
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #9BC3FF;
    animation: backbeamzoom 2.1s infinite ease-out !important;
    z-index: 1;
}

@keyframes backbeamzoom {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }
    50%{
        opacity: 0.01;
    }
    60%{
        transform: scale(2.5);
        opacity: 0;
    }
    100%{
        transform: scale(1);
    }
}

.front-beam {
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #72ABFF;
    animation: frontbeamzoom 2.1s infinite ease-out !important;
    z-index: 2;

}

@keyframes frontbeamzoom {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }
    50%{
        opacity: 0.1;
    }
    70%{
        opacity: 0.01;
    }
    90%{
        opacity: 0.001;
    }
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}


.btn-beam{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    border: solid 3px #72ABFF;
    position: absolute;
    z-index: 3;
    transition: all 0.4s ease-in-out !important;
}


.btn-holder:hover .btn-beam{
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.4s ease-in-out;
}
.btn-holder:hover .plus-icon{
    cursor: pointer;
}

.plus-icon{
    position: absolute;
    z-index: 4;
    font-size: 25px;
}

@media (max-width: 1200px) {
    .btn-beam{
        width: 55px;
        height: 55px;
    }
    .back-beam, .front-beam{
        width: 45px;
        height: 45px;
    }
    .plus-icon{
        font-size: 20px;
    }

}
@media (max-width: 992px){
    .btn-beam{
        width: 50px;
        height: 50px;
    }
    .back-beam, .front-beam{
        width: 40px;
        height: 40px;
    }
    .plus-icon{
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    .btn-beam{
        width: 45px;
        height: 45px;
        border: solid 2px #72ABFF;
    }
    .back-beam, .front-beam{
        width: 35px;
        height: 35px;
    }
    .plus-icon{
        font-size: 15px;
    }
}
@media (max-width: 612px) {
    .btn-beam{
        width: 40px;
        height: 40px;
        border: solid 2px #72ABFF;
    }
    .back-beam, .front-beam{
        width: 30px;
        height: 30px;
    }
    .plus-icon{
        font-size: 14px;
    }
}
@media (max-width: 512px) {
    .btn-beam{
        width: 30px;
        height: 30px;
        border: solid 2px #72ABFF;
    }
    .back-beam, .front-beam{
        width: 25px;
        height: 25px;
    }
}