.mc__form-container {
  width: 100%;
  margin: 0 auto;
  transition: height 0.2s ease; 
}

.custom-input {
  position: relative;
  width: 100%;
  max-width: 630px;
  height: 80px;
  background-color: #f4f4f4;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 15px;
}

.custom-input input::placeholder {
  color: #bdbdbd;
}

.custom-input input {
  flex: 1;
  border: none;
  background: none;
  font-family: 'LeferiBaseType-Bold', 'Leferi BaseType';
  font-size: 22px;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 5px;
  color: #777777;
  outline: none;
}

.custom-button {
  background-color: #ff6966;
  color: #ffffff;
  font-family: 'LeferiBaseType-Bold', 'Leferi BaseType';
  font-weight: bold;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 38px;
  
  font-size: 20px;
  padding: 15px; 
  width: 100%;
  max-width: 170px;
  height: 100%;
  max-height: 60px;
}

.custom-input button.custom-button:hover {
  background-color: #ff6966;
}
 

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f4f4f4 inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #777777;
  height: 40px;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #f4f4f4 inset;
  /*your box-shadow*/
  -webkit-text-fill-color: #777777;
}

.red-text {
  color: #ea3633;
}

.mc__alert {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 40px;

  /* If "sending" this will animate the text.*/
  /* Keyframes for this will be included below.*/
  &--sending {
    color: #7fc1ff;
    // color: var(--v-highlight2);
    -webkit-animation: fade-loop 0.8s ease-in-out infinite alternate-reverse
      both;
    animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
  }

  /* "success" status styles with text color */
  &--success {
    color: #43ac2d;
  }

  &--error {
    color: #ff6966;
    font-weight: bold;

    /* This addresses any links the error message includes */
    a {
      color: #ff0000;
      transition: 0.3s ease-in-out;
      font-weight: normal;
      display: block;
      opacity: 0.7;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }
  }
}

@-webkit-keyframes fade-loop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-loop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .mc__form-container {
    width: 90%;
  }
  .custom-input {
    height: 50px;
    width: 350px;
  }
 
  .custom-button {
    font-size: 15px;
    max-width: 100px; 
    max-height: 40px;
    padding: 10px; 
  }

  .custom-input input {
    font-size: 14px;
  }
 
 

  .result {
    font-size: 10px;
  }
}
