.footercharacters-style {
  background-color: rgb(255, 255, 255);  
  overflow: hidden; /* Ensures overflow is hidden for the entire section */
}

.character-wrapper {
  position: relative;
  overflow: visible; /* Allows overflow for individual characters */
  height: 200px;
}

.character-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  height: 200px;
  transition: transform 1s;
  cursor: pointer;
}

.character-container img {
  position: relative;
  bottom: -45px; /* Adjust this to hide the bottom part of the character */
  max-width: 170px; 
  transform: translateY(0%);
  transition: transform 1s;
}

.character-container:hover img {
  transform: translateY(-28px); /* Adjust this to control the hover effect */
  animation: moveUpDown 1.3s ease-in-out forwards;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(28%);
  }
  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media (max-width: 767px) {
  .character-wrapper {
    height: 130px;
  }
  .character-container img {
    max-width: 110px; 
    bottom: -60px; 
  }
}
