/* FooterPlayground.css */
 
.footer-container {
  margin: 0;
  height: 100vh;
  background-color: #304444;
  color: white;
}

.outer {
  overflow: hidden;
}

.scroll {
  display: flex;
  width: 300%;
  overflow-x: hidden;
}

.footer-sections {
  height: 100vh;
  width: 100%;
  display: grid;
  place-content: center;
  flex: 1; 
}

 

.footer-sections:nth-of-type(2) {
  background-color: #2b7d7b;
}

.footer-sections:nth-of-type(3) {
  background-color: #462088;
}
