/* Use App.css for application-level styles: This can include layouts and grids that are common across all major sections of the application, common background styles, or default configurations for larger UI components that are reused throughout the app. */

/* App.css */

/* Base styles */
* {
  box-sizing: border-box !important;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  /* min-height: 100%; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  max-width: 2560px;
  width: 100%;
  margin: auto;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* justify-content: center; */
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

/* .containers {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
} */
.wrapper {
  width: 100%;
}

section {
  width: 100%;
}

/* .hero-sections {
  background-color: #72ABFF;
  margin: 0;
  padding: 0;
  min-height: 100vh;
} */

/* a {
  text-decoration: none;
  color: inherit;
} */

/* input,
button,
textarea,
select {
  font: inherit;
} */

/* Responsive Typography */
/* h1 {
  font-size: 2rem;
  line-height: 1.2;
}

p {
  font-size: 1rem;
  line-height: 1.6;
} */

/* @media (max-width: 1200px) {
  h1 {
    font-size: 1.75rem;
  }

  p {
    font-size: 0.95rem;
  }
} */

@media (max-width: 768px) {
  .wrapper {
    /* padding: 0 0.5rem;   */
  }

  /* h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  } */
}

@media (max-width: 480px) {
  /* h1 {
    font-size: 1.25rem;
  }

  p {
    font-size: 0.85rem;
  } */

  .wrapper {
    /* padding: 0 0.25rem;   */
  }
}
