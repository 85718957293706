/* * {
  box-sizing: border-box;
} */



.footer-container {
  overflow-x: auto;
  width: 100%;       
  height: auto;      
  position: relative;
  
}

.footer-container::-webkit-scrollbar {
  display: none;  
}

.scroll {
  display: flex;
  min-width: 100%;    
  overflow-x: auto;  
  position: relative;
}

.footer-st {
  position: relative;
  display: flex;
  align-items: center;
  background: url('/public/images/userAccount/User\ account_PlayZone_01.svg') no-repeat center;
  background-size: cover;
  width: 300%; 
  min-height: 1567px;
  overflow: visible;  
}

.draggable-character {
  width: 120px; 
  height: auto;
  margin: 10px;
  cursor: grab;
  position: relative;  
}


@media (max-width: 767px) {
  .footer-st {
    width: 300%; 
    height: auto;
    min-height: 400px;
  }

  .draggable-character {
    width: 4%;
  }
}
