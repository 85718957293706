 
.section4-title-style {
  color: #ff6e6b;
  font-size: 60px;
  align-items: center;
  font-family: 'ef_hyungydororong';
}

.section5-row-layout {
  padding-top: 8%;
  padding-bottom: 8%;
}

.cloud-small {
  width: 100%;
  max-width: 400px !important;
}

.cloud-medium {
  width: 100%;
  max-width: 410px !important;
}

.cloud-large {
  width: 100%;
  max-width: 550px !important;
}

.cloud-text-2 {
  font-family: 'LeferiBaseType';
  text-align: center;
  color: #414141;
  padding: 5% !important;
  margin-top: 5%;
  font-size: 0.8rem;
  line-height: 2;
}

.plus-btn-style {
  width: 65% !important;
}

@media (max-width: 1440px) {
  .cloud-text-2 {
    font-size: 0.6rem;
  }
}
@media (max-width: 990px) {
  .cloud-text-2 {
    font-size: 0.4rem;
    padding: 1px !important;
    margin-top: 7%;
  }

  .plus-btn-style {
    width: 40%;
  }

  .cloud-small {
    max-width: 200px !important;
  }

  .cloud-medium {
    max-width: 250px !important;
  }

  .cloud-large {
    max-width: 350px !important;
  
  }

  .align-left-sm {
    display: flex;
    justify-content: flex-start !important;
  }

  .align-center-sm {
    display: flex;
    justify-content: center !important;
  }

  .align-right-sm {
    display: flex;
    justify-content: flex-end !important;
  }
}

@media (max-width: 767px) {
  .section4-title-style {
    font-size: 25px;
  }

  .cloud-text-2 {
    font-size: 0.4rem;
    /* margin-top: 10px; */
    /* padding: 10%; */
  }

  .plus-btn-style {
    width: 15% !important;
  }

  .cloud-small {
    max-width: 160px !important;
  }

  .cloud-medium {
    max-width: 180px !important;
  }

  .cloud-large {
    max-width: 200px !important; 
  }
}
