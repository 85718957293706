
.wagzakApp-section1 {
    /* margin-bottom: -15px;   */
    width: 100%;
    height: 100%;
    padding-top: 4%;
    transition: padding-top 0.3s ease;
}

.wagzakApp-section2 {
    padding-top: 5vh;
}

 
@media (max-width: 1520px) {
    .wagzakApp-section1 {
        padding-top: 7%; 
    }
}