/* SplashScreen.css */
.splash-screen {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffff; /* Adjust based on your design */
  z-index: 1000;
}

.loading-lottie {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* opacity: 1;  */
  /* transition: opacity 2s ease-in-out; */
}

.loading {
  font-family: 'ef_hyungydororong';
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

 
@media only screen and (max-width: 678px) {
  .loading-lottie {
      width: 70% !important; 
  }

  .loading {
      font-size: 25px;
      top: 65%;
  }
}