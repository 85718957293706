/* Background Colors */

.bg-color-blue {
    background-color: #72abff;
}

.bg-color-green {
    background-color: #a8e25d;
}

.bg-color-white {
    background-color: white;
}


/* Font Colors */
.font-color-black {
    color: #000;;
}

.font-color-blue {
    color: #72abff;
}

.font-color-purple {
    color: #7040dc;
}

.font-color-red {
    color: #ff4945;
}

.font-color-yellow {
    color: #ffff00;
}

.font-color-green {
    color: #a8e25d;
}

.font-color-orange {
    color: #ffa82d;
}
