 

.footerIcons-style {
    height: 100%;
    width: 100%; 

} 

.footerIcons-title-style {
    font-family: 'LeferiBaseType';
    color: white;
    font-size: 30px;
    align-items: center;
   
}



@media (max-width: 767px) {

    .footerIcons-title-style {
        font-family: 'LeferiBaseType';
        color: white;
        font-size: 18px;
        align-items: center; 
   
    }

    .btn-style{
        width: 60%; 
    }
   
   
}   

 