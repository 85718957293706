.aboutUsSection1 {
  width: 100%;      
  height: 100%;
  padding-top: 7%;
  transition: padding-top 0.3s ease; 
}
 
.aboutUsSection2 {
  background-color: white; 
  padding-top: 8vh;
  padding-bottom: 5vh;
}

.aboutUsSection3 {
  background-color: white;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.aboutUsSection6 {
  padding-top: 15px;
}
@media (max-width: 1520px) {
  .aboutUsSection1 { 
    padding-top: 13%;
  }
}

@media (max-width: 767px) {
  .aboutUsSection1 { 
    padding-top: 25%;
  }
  
  .aboutUsSection2 {
    padding-top: 5vh;
  }
}
