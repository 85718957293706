
.image-popup {
    transform: scale(1.1); 
    transition: height 1s;
}


.aboutus-section2-style {
    background-color: white;
}

.spline {
    height: 50vh;
}


.aboutus-section2-title {
    font-family: 'LeferiBaseType';
    font-size: 30px;
    font-stretch: normal;
    line-height: 2.60;
    letter-spacing: normal;
    white-space: nowrap;

}
 
.aboutus-section2-text {
    font-family: 'LeferiBaseTypeNormal';
    font-size: 20px;
    font-stretch: normal;
    line-height: 2.60;
    letter-spacing: normal;
    white-space: nowrap;

}


@media (max-width: 767px) {

    .aboutus-section2-title {
        font-family: 'LeferiBaseType';
        font-size: 20px;
        font-stretch: normal;
        line-height: 2.60;
        letter-spacing: normal;
        white-space: nowrap;

    }



    .aboutus-section2-text {
        font-family: 'LeferiBaseTypeNormal';
        font-size: 10px;
        font-stretch: normal;
        line-height: 2.60;
        letter-spacing: normal;
        white-space: nowrap;

    }

    .halmi-mg {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}