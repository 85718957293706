
.aboutus-container-style {
  position: relative; /* Add this to create a new stacking context if needed */
  overflow: visible; /* Ensure the container does not clip overflowing children */
}

.aboutus-header-title {
  font-family: 'ef_hyungydororong';
  text-align: center;
  color: white;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 7px;
  white-space: nowrap;
  overflow: visible; /* Ensure the text itself is not clipped */
  margin-bottom: -3%;
  z-index: 1010;
  position: relative; /* Ensure z-index works */
}
@media (max-width: 1400px) {
  .aboutus-header-title {
    font-size: 25px;
    letter-spacing: 0px;
  }
}
@media (max-width: 767px) {
  .aboutus-header-title {
    font-size: 25px;
    letter-spacing: 0px;
  }
}

.aboutus-container-style img {
  display: block;
  max-width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}
